@import "../../Style/base";
.flexBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .imgBox {
    flex: 0 0 50%;
    @include media("<xs-phone"){
      flex: 0 0 100%;
    }
  }
    &.exploreBox{
      display: flex;
        flex-wrap: wrap;
        align-items: center;
      .imgBox{
        cursor: pointer;
        flex: 0 0 25% !important;
        &:first-child{
          margin: 0;
        }
        @include media("<tablet-x") {
          flex: 0 0 33.33% !important;
        }
      
        @include media("<phone") {
          flex: 0 0 50% !important;
        }
      
        @include media("<xs-phone") {
          flex: 0 0 100% !important;
        }
      }
    }
  .imgBox {
   
    position: relative;
    display: inline-block;
    padding: 12px;
    vertical-align: top;
    width: 100%;
    cursor: pointer;
    
    &:first-child{
        margin-top: 35px;
        @include media("<xs-phone"){
          margin-top: 0;
        }
    }
    video{
      border-radius: 18px;
      display: block;
      height: auto;
      width: 100%;
    }
    img {
      border-radius: 18px;
      display: block;
      height: auto;
      width: 100%;
    }
    .profileDetail {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      color: #fff;
      margin: 20px;
      font-size: 12px;
      img {
        object-fit: cover;
        width: 35px;
        height: 35px;
        margin-right: 8px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }
}

.scrollable {
  overflow-y: auto;
  max-height: 800px; 
}

.scrollable::-webkit-scrollbar{
  display: none;
}