@import "../../Style/base";
.cardHolder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 35px;
    flex-wrap: wrap;
    gap: 15px;
    .item{
        flex: 0 0 25%;
        @include media("<phone"){
        flex:  0 0 48%;
        }
        @include media("<xs-phone"){
        flex: 0 0 200px;
        margin: auto;
        }
    }
    .box{
        display: flex;
        margin-bottom: 50px;
        small{
            vertical-align: top;
        }
    }
    .icon{
        position: absolute;
        left: 50%;
        top: 12px;
        transform: translateX(-50%);

    }
    
}