@import "../../Style/base";

.header {
  background: #201e23;
  z-index: 3;
  position: fixed;
  width: 100%;
  top: 0;
  transition: all ease 0.3s;

  .rightSide {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 0;
    width: 100%;
    margin-left: 100px;

    @include media("<widescreen") {
      margin-left: 150px;
    }
    @include media("<desktop") {
      margin-left: 12px;
    }
    
    @include media("<xs-phone") {
      margin-left: 25px;
      width: 100%;
    }
  }

  .iconBtn {
    background: linear-gradient(321deg, #73ff66 0%, #19850f 100%);
    border-radius: 30px !important;
    padding: 0 10px;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      color: #fff;
      opacity: 0.9;
    }

    .icon {
      background-color: #fff;
      color: #000;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 12px;
    }

    span {
      margin-right: 5px;
    }

    img {
      width: 28px;
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;
    }
  }

  .profileBtn {
    height: auto;
    width: 100px;

    img {
      border: 3px solid #fff;
      width: 45px;
      height: 45px;
      display: block;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.overlay {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.floatingResults {
  background-color: #3d3b3f;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  width: 40%;
  color: black;
  border-radius: 8px;
}

.floatingLoader {
  background-color: #3d3b3f;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 60px;
  overflow-y: hidden;
  width: 40%;
  border-radius: 8px;
}
