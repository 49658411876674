@import "../../Style/base";
.modalContant{
    max-height: 407px;
    overflow: scroll;
    width: 100%;
    text-align: justify;
    padding: 15px 25px;
    overflow-x: hidden;
    @include media("<xs-phone") {
      padding: 10px 10px 10px 0;
    }
  }