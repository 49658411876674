@import "./include-media";
.formHolder {
  max-width: 380px;
  .form-control {
    width: 100%;
    border-radius: 30px;
    margin: 15px 0;
    padding: 12px 22px;
    font-family: 'Gilroy';
    font-weight: 500;
    color: #6d6771;
    @include media("<xs-phone") {
      padding: 9px 18px;
    }
    &::placeholder {
      color: #6d6771;
      font-size: 12px;
      font-weight: 600;
    } 
  }
}
.login-form {
  text-align: center;
  width: 50%;
}
.form-group {
  position: relative;
}
.forms{ 
  &.textholder{
    color: #fff;
    .form-control{
      &::placeholder{
        color: #fff;
      }
    }
  
  }
  .form-control {
    font-size: 14px;
    border-radius: 0;
    border-bottom: 1px solid #454545 !important;
    border-left: none;
    border-right: none;
    border-top: none;
    padding: 1rem 0.75rem;
    color: #fff;
    background-color: transparent;
    &:focus {
      box-shadow: none;
    }
}
}
.form-control {
  font-size: 14px;
  border-radius: 50px;
  border: 1.5px solid #fff;
  padding: 0.575rem 0.75rem;
  color: #fff;
  background-color: #fff;

  &:focus {
    box-shadow: none;
  }
}
.userFrom {
  .form-group {
    border-bottom: 1px solid #454545;
    margin-bottom: 10px;
    .form-control {
      background-color: transparent;
      border-radius: 0;
      border: none;
      padding: 5px 0 10px 0;
      font-size: 16px;
      font-weight: 400;
      &:focus {
        color: #fff;
      }
    }
    .form-label {
      font-size: 12px;
      margin: 0;
      color: #fff;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-switch {
  .form-check-input {
    background-color: rgba(96, 96, 96, 1);
    background-image: url(../Images/knob.png);
    height: 25px;
    width: 40px;
    background-position: -5px -1px;
    background-size: 30px;
    border: none;
    &:checked {
      background-color: rgb(251, 203, 80);
      background-position: 14px -2px;
      background-size: 30px;
    }
    &:focus {
      background-image: url(../Images/knob.png);
      height: 25px;
      width: 40px;
      background-size: 32px;
      border: none;
      box-shadow: none;
    }
  }
}

.invalid-feedback{
  margin-left: 18px;
}

