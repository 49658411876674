@import "../../Style/base";
.footer{
background: #232323;
margin: 0;
position: relative;
bottom: 0;
left: 0px;
width: 100%;
padding: 10px 0;
display: flex !important;
flex-direction: column !important;
  .rightSide {
    width: calc(100% - 130px);
    @include media('<xs-phone'){
      width: 100%;
    }
    [class*="navBar"]{
      align-items: center;
      [class*="navbar-nav"]{
        gap: 25px;

        @include media("<xs-phone"){
          width: 100%;
          gap: 0;
         }
      
        @include media("<xs-phone"){
          gap: 0px;
          text-align: left !important;
         }
      }
      
    }
}
}