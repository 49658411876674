@import "~bootstrap/scss/bootstrap";
@import "Style/base";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Questrial&display=swap");
@import url(https://db.onlinewebfonts.com/c/bf05270ea2c7289d011ddc5d9898635f?family=GlOrY+BoLd);
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&family=Roboto:ital,wght@1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Glass+Antiqua&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0b0213;
  font-family: $Poppins;
  color: #9b9b9b;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// input,.form-control{
//   border: 1px solid transparent !important;f
//   color: #fff !important;
//   &:focus{
//     background: rgba(255, 255, 255, 0.13);
//     border: 1px solid rgba(255, 255, 255, 0.2) !important;
//     box-shadow: none;
//     outline: none;
//   }
// }
.container {
  max-width: 1170px !important;
}

nav {
  font-family: $Poppins;
}

.nav-opener {
  font-size: 38px;
  margin: 12px 0;
  display: none;
  background-color: transparent;
  z-index: 9999;
  position: relative;
  &.active {
    position: fixed;
    top: 60px;
    z-index: 99999999999;
    left: 230px;
  }
  @include media("<tablet-x") {
    display: block;
  }
}

.aside-responsive {
  border-right: 1px solid rgba(255, 255, 255, 0.31);

  &.profile-setting {
    border: none !important;

    @include media("<tablet-x") {
      border-radius: 12px;
      border: none;

      &.active {
        min-height: 100vh;
        overflow: auto;
        left: 0;
        top: 0;

        .nav-item {
          display: block !important;
        }
      }
    }
  }

  @include media("<tablet-x") {
    border: none;
    width: 80px;
    // position: fixed;
    z-index: 99999;
    transition: all ease 0.3s;
  }

  &.active {
    width: 320px;
    background-color: #1c1c1c;
    transition: all ease 0.3s;
    height: 100vh;
    #aisde {
      background-color: #1c1c1c !important;
    }
  }

  #aisde {
    position: fixed;
    top: 57px;
    min-height: 100vh;
    height: 100%;
    width: 295px;
    transition: all ease 0.3s;
    overflow: auto;

    @include media("<tablet-x") {
      display: none;
    }

    .asideHolder {
      height: 100%;

      @include media("<tablet-x") {
        height: auto;
      }
    }

    &.active {
      display: block;
    }
  }
}

.content-responsive {
  section {
    max-width: 800px;
    margin: auto;
  }

  @include media("<tablet-x") {
    width: 100%;
    transition: all ease 0.3s;
    padding-top: 10px !important;
  }
}

section {
  padding: 50px 75px;
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - 80px);

  @include media("<tablet-x") {
    padding: 20px 50px;
  }

  @include media("<xs-phone") {
    padding: 20px 10px;
  }

  &.purchaseBg {
    background: url("../Images/coinBg.png");
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.banner {
  background-image: url("../Images/bannerBg.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-size: cover;
  padding: 50px 0;

  &.otherBanner {
    background-image: url(../Images/otherBanner.png);
  }
}

.navBar {
  margin: 0 0 0 20px;

  .responsive-nav {
    @include media("<tablet-x") {
      position: fixed;
      right: -280px;
      top: 0;
      bottom: 0;
      width: 250px;
      z-index: 999999;
      height: 100%;
      margin: 0;
      background-color: #201e23;
      align-items: flex-start;
      transition: all ease 0.3s;

      &.active {
        right: 0;
        transition: all ease 0.3s;
        max-height: 100vh;
        overflow: auto;
      }

      .navbar-nav {
        margin-top: 120px;
      }

      .nav-link {
        padding: 20px 10px;
        border-bottom: 1px solid #868686;
        width: 200px;
        margin-left: 21px;
      }

      .custom-icon2 {
        font-size: 25px;
        position: absolute;
        right: 10px;
      }
    }

    #profile-btn {
      display: none;

      @include media("<tablet-x") {
        display: block;
        padding: 20px;
        position: unset !important;
      }
    }
  }

  .custom-icon1 {
    font-size: 20px;
    display: none;

    @include media("<tablet-x") {
      display: block;
    }
  }

  &.aside {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .nav-link {
    color: #9b9b9b;
    display: flex;
    align-items: center;
    font-size: 12px;

    img {
      width: 16px;
      margin-right: 10px;
    }

    i {
      margin-right: 10px;
      font-size: 18px;
    }

    &.active,
    &:hover {
      color: #ffcd2f;
      filter: brightness(0) saturate(100%) invert(93%) sepia(24%)
        saturate(7458%) hue-rotate(332deg) brightness(108%) contrast(100%);
    }
  }

  .nav-links {
    .nav-link {
      &:hover {
        filter: none;
      }
    }
  }

  .home-icon {
    font-size: 14px;

    img {
      color: #ffcd2f;
      filter: brightness(0) saturate(100%) invert(93%) sepia(24%)
        saturate(7458%) hue-rotate(332deg) brightness(108%) contrast(100%);
      width: 17px;
    }
  }
}

main {
  margin-top: 80px;

  @include media("<tablet-x") {
    margin-top: 56px;
  }
}

.main-page {
  .page-sides {
    width: 310px;
  }

  .center-side {
    width: calc(100% - 620px);

    @include media("<widescreen") {
      width: calc(100% - 310px);
    }

    @include media("<tablet-x") {
      width: 100%;
    }
  }
}

#profile-btn {
  @include media("<tablet-x") {
    display: none;
  }
}

.accordion {
  font-family: $pt;

  .following {
    min-height: 100vh;
    height: 100%;
    position: fixed;
    width: 290px;
    top: 50px;
    border-left: 1px solid rgba(255, 255, 255, 0.31);

    @include media("<widescreen") {
      min-height: auto;
      height: auto;
      border-left: unset;
      position: relative;
      top: 0;
      width: 258px;
    }

    .all-text {
      margin-right: 43px;
      margin-left: 20px;
      color: #f6cc38;
      font-weight: 700;
      font-size: 14px;

      @include media("<widescreen") {
        margin: 0 10px 0 0;
        padding-right: 46px;
      }
    }
  }

  &.active {
    &.following-active {
      .accordion-item {
        background: #201e23;
      }
    }

    border-radius: 14px;

    .accordion-item {
      background-color: #1c1c1c;

      .accordion-header {
        .accordion-button {
          border-radius: 14px 14px 0 0;
        }
      }

      .accordion-collapse {
        border-radius: 0 0 14px 14px;
      }
    }
  }

  .accordion-item {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 14px;
    color: #fff;
    border: none;
    margin-bottom: 12px;

    .accordion-header {
      .accordion-button {
        background: rgba(255, 255, 255, 0.11);
        border-radius: 14px;
        padding: 10px;
        color: #fff;

        &:after {
          background-image: url("../Images/chevron-down.png");
          background-color: rgba(255, 255, 255, 0.11);
          padding: 12px;
          border-radius: 50%;
          background-position: center;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .accordion-collapse {
      background: rgba(255, 255, 255, 0.11);

      &:active {
        border-bottom: 0;
      }
    }
  }
}

.badge {
  border-radius: 25px;
  cursor: pointer;
  display: block;
  font-size: 12px;

  &.explore-badge {
    background-color: transparent !important;
    border: 1px solid #676767;
    margin-bottom: 35px;

    @include media("<tablet") {
      margin: 0;
    }
  }

  &.active {
    background-color: #ffcd2f !important;
    color: #000;
  }
}

.phonverify .ReactInputVerificationCode__item {
  border-radius: 100px;
  border: 1px solid #dec761;
  // width: 45px;
  // height: 70px;
  // line-height: 73px;
  width: 45px;
  height: 59px;
  line-height: 59px;
  box-shadow: none;

  @include media("<xs-phone") {
    font-size: 20px;
    font-weight: 600;
  }

  &:focus-visible {
    outline: none !important;
  }
}

.ReactInputVerificationCode__container {
  width: unset !important;
}

.ReactInputVerificationCode__item {
  font-size: 20px;
  font-weight: 600;

  @include media("<tablet") {
    font-size: 1rem;
  }
}

.modal-backdrop {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.9);
}

.modal {
  &.infoModal {
    .modal-content {
      border-radius: 38px;
      border: 2px solid #dcdcdc;
      background: #1c1c1c !important;
      padding: 0;
      // &.black{
      //   background: #000;
      // }
    }

    &.judge {
      .modal-content {
        .modal-body {
          padding: 20px 46px !important;
          font-size: 12px;
        }
      }
    }

    .modal-header {
      padding: 0;
      height: 0;

      .closeBtn {
        top: 12px;
        right: -5px;
      }
    }

    .modal-body {
      background-color: transparent !important;

      &.congrats {
        background-image: url(../Images/tournamentEntrySuccess.png);
        background-size: 450px;
        background-position: center -90px;
        background-repeat: no-repeat;
        background-position-y:center;
      }
      &.coins_winner {
        background-image: url(../Images/winner-box.png);
        background-size: 450px;
        background-position: center -90px;
        background-repeat: no-repeat;
        background-position-y:center;
      }
      &.tournament{
        background-image: url(../Images/tournamentCup.png);
        background-size: 300px;
        background-position: center -90px;
        background-repeat: no-repeat;
        background-position-y:center;
      }

      &.Postsuccess {
        background-image: url(../Images/tournamenmodal.png);
        background-size: 420px;
        background-position: center -90px;
        background-repeat: no-repeat;
      }
    }

    .modal-dialog {
      max-width: 350px;
    }

    .closeBtn {
      background-color: #d10a0b;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 30px;
      position: relative;
      top: -25px;
      right: -25px;
      cursor: pointer;
      z-index: 9999;
    }
  }

  &.comment-modal {
    .modal-body {
      background-color: transparent;
      padding: 0;
      margin: 0;
    }
  }

  &.uploadModal {
    .modal-body {
      background-color: black;
      border-radius: 10px;
    }
  }

  &.transparentModa {
    .modal-content {
      background-color: transparent;
      border: none;
    }

    .modal-body {
      background-color: transparent;
      padding: 32px 0;
    }
  }

  .btn-close {
    filter: brightness(0) invert(1);
    opacity: 1;
  }

  .modal-content {
    background: #3c3a40;
    border-radius: 30px;
    padding: 20px;

    @include media("<xs-phone") {
      padding: 16px 8px;
    }
  }

  .modal-header {
    border: none;
    padding-top: 0;
    padding-bottom: 10px;

    .modal-title {
      display: flex;
      align-items: center;
      color: #fff;
      cursor: pointer;

      i {
        font-weight: 400;
        font-size: 20px;
      }

      h6 {
        font-size: 13px;
        margin-bottom: 0;
        margin-left: 12px;
      }
    }
  }

  .title {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding-left: 20px;
    text-align: center;
  }

  .modal-body {
    background: #2c2930;
    border-radius: 30px;
    padding: 20px 30px;
    margin: 20px 5px;
    text-align: center;

    @include media("<xs-phone") {
      padding: 15px;
    }

    &.checkEmail {
      background: #201e23;
      border: none !important;
      padding: 0;
      margin: 0 auto;
      color: #fff;

      i {
        background: linear-gradient(180deg, #44ffbc 0%, #0d6344 100%);
        color: #fff;
        border-radius: 50px;
        padding: 10px;
        margin: 20px 0;
      }

      .reset-btn {
        padding: 0 50px;
        margin: 20px 0;
      }
    }

    &.subTitle {
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18pxpx;
      margin: 0;
    }

    .aboutLogo {
      display: flex;
      justify-content: center;
    }
  }
}

.aboutModal {
  .modalContant {
    max-height: 484px;
    overflow: scroll;
    width: 100%;
    text-align: left;
    padding: 15px;
    overflow-x: hidden;

    @include media("<xs-phone") {
      padding: 15px;
    }
  }

  .title {
    text-align: left;
    padding-left: 20px;
  }
}

.imgBox {
  width: 100%;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.alignRight {
  .modal-content {
    background: #201e23;
    border-radius: 10px;
    border: 1px solid #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    max-width: 294px;
    margin: 50px;

    @include media("<xs-phone") {
      max-width: 248px;
      margin: 71px 36px;
    }

    img {
      width: 110px;
    }
  }
}

.resetPassword {
  // background: rgba(11, 2, 19, 0.70);
  // backdrop-filter: blur(5px);

  .modal-content {
    background: #201e23;
    border-radius: 20px;
    border: 1px solid #fff;
    max-width: 294px;
    margin: 0 auto;
    padding: 0;
  }

  p {
    margin: 0;
  }
}

.delAccountModal {
  background-color: #07010c;

  .modal-content {
    background: #201e23;
    border-radius: 20px;
    padding: 0px 10px 10px 10px;
    max-width: 400px;
    margin: 0 auto;

    .title {
      padding-top: 8px;
    }
  }

  .delAccounts {
    background: #201e23;
    margin: 0;
    color: #fff;
    padding: 10px 33px;
    font-size: 12px;
  }

  .form-control {
    padding: 0.8rem 0.75rem;
  }
}

.successModal {
  background-color: #07010c;

  .modal-content {
    background: #f55;
    border-radius: 12px;
    padding: 10px;
    margin: 0 auto;
  }

  .succcess {
    border-radius: 12px;
    background: none;
    padding: 0;
    margin: 5px;
    text-align: left;
    color: #fff;

    img {
      margin-right: 15px;
      width: 35px;
    }
  }
}

.orginizeBadges {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
    }
  }

  .modal-content {
    border-radius: 30px;
    background: #201e23;
    padding: 20px 20px 10px 20px;
    margin: 0 auto;
    max-width: 350px;
  }

  .organize {
    border-radius: none;
    background: none;
    padding: 0;
    margin: 5px;
    text-align: left;
    font-size: 11px;

    img {
      margin-right: 15px;
    }
  }
}

.card {
  position: relative;
  background: #211f24;
  padding: 12px;
  border-radius: 8px;

  &.profileCard {
    border-radius: 30px !important;
    padding: 20px !important;
  }
}

.iconBtncust {
  background: linear-gradient(321deg, #73ff66 0%, #19850f 100%);
  border-radius: 30px !important;
  padding: 0 10px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Gilroy;
  max-width: 108px;
  margin: auto;
  max-width: 85px;
  height: 30px;

  &:hover {
    color: #fff;
    opacity: 0.9;
  }

  img {
    width: 20px;
    margin-right: 5px;
  }
}

.vertical-NavHolder {
  .nav {
    .nav-item {
      position: relative;

      @include media("<tablet-x") {
        display: none;
      }

      &:after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid #262626;
      }

      .nav-link {
        color: #fff;
        display: flex;
        align-items: flex-start;
        padding: 20px;
        max-height: 84px;

        &.active {
          background: rgba(32, 30, 35, 0.4);
          border-radius: 10px;
        }

        .profileDetails {
          color: #9a9a9a;
          font-size: 12px;
          padding-top: 9px;

          p {
            margin: 0;
          }
        }

        span {
          border-radius: 12px;
          background: #2f2c35;
          margin-right: 20px;
          padding: 7px;
        }

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.faqTitle {
  font-size: 16px;
  color: #fff;
  font-family: $Poppins;
  font-weight: 700;
}

.faqSubTitle {
  font-size: 14px !important;
  color: #fff;
  font-family: $Poppins;
  font-weight: 500;
}

.ruleList {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #fff;
  line-height: 20px;
  text-align: start;
  font-size: 12px;
  counter-reset: section;

  li {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 18px;

    &:after {
      position: absolute;
      counter-increment: section;
      content: counter(section) ".";
      left: 0;
      top: 0;
      background: linear-gradient(
        180deg,
        #ffe486 0%,
        #ffcc4e 51.56%,
        #ffd02b 100%,
        #d2a61b 100%
      );
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      color: #0c0a35;
      font-family: Quicksand;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      padding-left: 1px;
    }

    &:before {
      position: absolute;
      content: "";
      left: 1px;
      top: 1px;
      background: transparent;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border-left: 4px solid #fff;
      z-index: 1;
      transform: rotate(39deg);
    }
  }
}

.sectionHolder {
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.tab-card-box {
  background: #201e23;
  border-radius: 18px;
  padding: 20px 15px;
  text-align: center;

  h6 {
    color: #828184;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.form-select {
  &.form {
    background-position: 88% center !important;
  }

  background: none;
  border-radius: 20px;
  border: 1px solid #454545;
  color: #fff;
  padding: 10px 13px;
  background-image: url("../Images/arrowDown.png");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-color: #201e23;
  font-size: 14px;

  &:focus {
    box-shadow: none;
    border: 1px solid #454545;
  }
}

.postionBottom {
  position: absolute;
  bottom: 0;
  width: 300px;
  left: 50%;
  margin: 10px;
  transform: translateX(-50%);

  @include media("<tablet-x") {
    width: unset;
  }
}

::-webkit-scrollbar-track {
  background: #3d3745;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 15px;
  background: #3d3745;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.32);
  border-radius: 15px;
}

.modal-md {
  max-width: 650px;
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  background: #00cc52;
  z-index: 9;
}

.chat {
  color: #fff;
  border-bottom: 1px solid #4d4d4dcc;
  position: fixed;
  right: 275px;
  bottom: 0;
  z-index: 9999;
  padding: 0;
  border-radius: 12px 12px 0 0;
  background: #393240;

  @include media("<tablet") {
    border: none;
  }

  .header {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.11);
    border-radius: 12px 12px 0 0;

    .cancel-icon {
      float: right;
      font-size: 18px;

      svg {
        margin-bottom: 8px;
      }
    }
  }

  .messages {
    display: flex;
    flex-direction: column;
    height: 280px;
    width: 280px;
    overflow: auto;
    line-height: 1.5;
    padding: 0 10px;

    .message {
      margin-bottom: 10px;

      &.sent {
        text-align: end;
        padding-right: 42px;

        .messageBox {
          background: linear-gradient(
            180deg,
            #ffe486 0%,
            #ffcc4e 51.56%,
            #ffd02b 100%,
            #d2a61b 100%
          );
          color: #000;
          border-radius: 20px 20px 0px 20px;
          padding: 12px 15px;
          text-align: center;
        }
      }

      .messageBox {
        border-radius: 20px 20px 20px 0px;
        background: #5e5863;
        display: inline-block;
        padding: 10px 15px;
        min-width: 65px;
        max-width: 80%;
        overflow-wrap: anywhere;

        .message-user {
          font-size: 10px;

          img {
            width: 100px;
            margin-bottom: 10px;
          }
        }

        .message-text {
          word-break: break-all;
          font-size: 10px;
          text-align: left;
        }
      }
    }
  }

  .senderBox {
    position: relative;

    .userImg {
      margin: 0px 0 0 10px;

      img {
        width: 38px;
        height: 38px;
        object-fit: cover;
      }
    }
  }

  .userImg {
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    margin: 10px 10px 0 0;

    img {
      width: 38px;
      height: 38px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .receiverBox {
    position: relative;

    .message {
      padding-left: 42px;
    }

    .userImg {
      left: 0;
      right: unset;
    }
  }

  .sendBox {
    position: relative;

    .iconBox {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        font-size: 22px;
        color: #8f92a1;

        &:hover {
          color: #ffcd2f;
          cursor: pointer;
        }
      }
    }

    .form-control {
      border-radius: 12px 12px 0 0;
      background-color: #646370;
      color: #fff;
      border: none;
      padding: 15px 100px 15px 15px;

      &::placeholder {
        color: #838593;
        font-size: 12px;
      }
    }
  }
}

.stories-slider {
  .slick-track {
    margin: unset !important;
  }
}

.view-modal {
  .memeImg {
    img {
      height: 500px;
      object-fit: contain;
    }
  }
}

.customeErrors {
  color: red;
}

.inboxText {
  color: white;
  font-size: 17px;
  text-align: center;
}

.invalid-feedback {
  margin-bottom: -11px;
  margin-top: -7px;
}

.extraText {
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.overflowText {
  width: 90px;
  text-overflow: ellipsis;
  padding-top: 17px;
  margin-right: 10px;
  overflow: hidden;
}

.password {
  position: relative;

  span {
    position: absolute;
    top: 50%;
    color: #9b9a9a;
    right: 15px;
    transform: translateY(-50%);
    font-size: 18px;
  }
}

.check {
  display: flex;
  justify-content: space-between;

  label {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 300;
  }

  input {
    vertical-align: middle;
  }

  .form-check-input:checked {
    background-color: #fff;
    border-color: #fff;
  }

  .form-check-input:checked[type="checkbox"] {
    background-image: url(../Images/check12.png);
  }

  .form-check-input:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
}

aside.EmojiPickerReact.epr-main {
  height: 369px !important;
  width: 303px !important;
  position: absolute;
  bottom: 45px;
  left: 8px;
}

.emoji {
  aside.EmojiPickerReact.epr-main {
    height: 369px !important;
    width: 260px !important;
    position: absolute;
    bottom: 34px;
    left: 99px;
  }
}

.PostContentModal {
  // .fullscreen-modal {
  //   width: 100vw;
  //   height: 100vh;
  // }

  &.postView {
    .modal-header {
      padding: 0;

      .data {
        font-size: 12px;
      }
    }

    .modal-body {
      margin: 0;
      padding: 0;

      button {
        margin: 0 5px;
        background: #333333;
        border: none;

        svg {
          margin: 0 5px;
        }

        &.green {
          color: #00853d !important;

          &:hover {
            background: #00853d;
            color: #fff !important;
          }
        }

        &.red {
          color: #f44336 !important;

          &:hover {
            background: #f44336;
            color: #fff !important;
          }
        }
      }
    }
  }

  .modal-content {
    background: #201e23;

    .modal-title {
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      cursor: pointer;

      span {
        padding-left: 15px;
        font-size: 14px;
      }

      Button {
        font-size: 13px;
      }
    }

    .modal-body {
      background: #201e23;
      border-radius: 20px;

      &.dotsborder {
        border: 1px dashed rgba(255, 255, 255, 0.28);
        // height: 450px;
      }

      .bodyContant {
        padding: 120px;

        @include media("<tablet-x") {
          padding: 50px;
        }

        @include media("<xs-phone") {
          padding: 30px;
          font-size: 12px;
        }

        p {
          overflow: hidden;
        }

        ul {
          li {
            list-style-position: inside;
          }
        }
      }

      Form {
        width: 480px;
        margin: 0 auto;

        .form-control {
          background: #333333;
          border: none;
          color: #fff;

          &::placeholder {
            color: grey;
          }

          @include media("<xs-phone") {
            padding: 5px 10px;
            font-size: 12px;
          }
        }

        @include media("<tablet-x") {
          width: 100% !important;
        }
      }

      .imgBox {
        width: 35%;
        margin: 0 auto;
        height: 330px;
      }

      img {
        display: block;
        height: 315px;
        width: 100%;
        border-radius: 20px;
        object-fit: contain;

        @include media("<tablet-x") {
          height: auto;
        }

        @include media("<xs-phone") {
          height: auto;
        }
      }
    }

    .trimbtn {
      background: #333333;
      border: none;
      border-radius: 20px;
      border-radius: 20px;
      padding: 8px 35px;
      text-align: center;
      color: currentColor;
    }
  }
}

.imgBox {
  background: linear-gradient(180deg, #f7cd36 0%, #ffa63e 100%);
  border-radius: 30px;
}

.flagPost {
  background: #000000de;

  .modal-content {
    background: transparent;
    border: none;
    .modal-body {
      background: transparent;

      img {
        width: 150px;
        border-radius: 10px;
      }

      .icon-box {
        margin: 40px 0;
        color: #fff;

        i {
          display: block;
          margin: 15px 0;
        }

        span {
          margin-left: 15px;
        }
      }

      .flag-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .flag-options {
          width: 50%;
        }

        button {
          width: 100%;
          background: transparent;
          border: none;
          color: #fff;
          margin: 5px 0;
          font-size: 12px;
          padding: 10px;
          font-weight: bold;

          &.flag_selected {
            background: rgba(255, 255, 255, 0.25);
            border-radius: 5px;
           
          }
        }
      }

      .textBox {
        margin-top: 50px;

        h6 {
          padding: 5px 0;
        }

        .text {
          max-width: 50%;
          text-align: center;
          margin: 0 auto;
          color: rgb(236, 234, 234);
          font-size: 12px;
        }
      }
    }

    form {
      .form-control {
        font-size: 12px;
        border-radius: 10px;
        border: 1.5px solid #fff;
        padding: 0.575rem 0.75rem;
        color: #fff;
        background-color: transparent;
        width: 30%;
        margin: 30px auto;
      }
    }
  }
}

.notification {
  .bell-icon {
    position: relative;

    // &:after {
    //   position: absolute;
    //   content: "";
    //   top: 1px;
    //   right: 2px;
    //   width: 5px;
    //   height: 5px;
    //   border-radius: 50%;
    //   background-color: #ff0100;
    // }
  }

  .dropdown {
    margin: 0 10px;
    padding-top: 5px;

    svg {
      width: 20px;
      height: 20px;
      color: #9b9b9b;
      &:hover {
        filter: brightness(0) saturate(100%) invert(93%) sepia(24%)
          saturate(7458%) hue-rotate(332deg) brightness(108%) contrast(100%);
      }
      @include media("<tablet-x") {
        margin-left: 20px;
        margin-top: 10px;
      }
    }

    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 0 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);

      .white {
        color: #fff;
      }

      .yellow {
        color: #ffd02b;
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        color: #fff;
        display: flex;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        padding: 10px 10px 10px 10px;
        align-items: center;
        position: relative;

        &:after {
          position: absolute;
          
          top: 15px;
          left: 12px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #d2a61b;
        }

        &:last-child {
          border-bottom: none;
        }

        img {
          width: 30px !important;
          height: 30px !important;
          margin-right: 10px;
          border-radius: 50px;
        }

        .white {
          font-size: 12px;
          color: #fff;
          font-weight: 100;
          margin-bottom: 5px;
        }

        .light {
          font-size: 10px;
          color: #c5c3c3;
          font-weight: 100;
          margin: 0;
        }
      }
    }
  }

  .dropdown-menu {
    top: 145%;
    margin-top: var(--bs-dropdown-spacer);
    left: -272px;
    background: #201e23;
    border: 1px solid;
    @include media("<tablet-x") {
      margin-left: 20px;
      width: 240px;
      left: -25px;
    }
  }
}
.form-check-input:checked[type=checkbox]{
  background-color: #ffcc4e;
  border: none;
}

.purchasedPill{
  button{
    width:70px;
    height: unset;
  }
}
.btn-loading{
  border-width: 5px !important;
}

.back-arrow{
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
.image-preview-chat{
  position: fixed;
  bottom: 53px;
  img{
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  .remove-btn{
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    svg{
      background: #f9bf29;
      width: 18px;
      height: 18px;
      padding: 3px;
      border-radius: 50%;
    }
  }
}

.show-mouse-arrow{
  cursor: default !important;;
}

.report_modal::placeholder{
  color: #D9D9D9 !important;
}

.congrats_text{
  margin-top: 23rem !important;
}
.explore-post {
  padding: 20px;
  border-top: 1px solid #dee2e6;
}

.explore-grid {
  margin: 0 -5px;
}

.explore-item {
  padding: 0 5px;
  position: relative;
}

.explore-image-container {
  aspect-ratio: 1;
  overflow: hidden;
  cursor: pointer;
}

.explore-image-container img {
  object-fit: cover;
  transition: transform 0.3s ease;
}

.explore-image-container:hover img {
  transform: scale(1.05);
}

.profileDetail{
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex
;
  align-items: center;
  color: #fff;
  margin: 20px;
  font-size: 12px;
  img{
    object-fit: cover;
    width: 35px;
    height: 35px;
    margin-right: 8px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: block;
  }
  span{
    color: #fff;
    font-size: 12px;

  }
}

.EditPostModal{
  background-color: #000000ab;
}

.scrollable {
  overflow-y: auto;
  max-height: 800px; 
}

.scrollable::-webkit-scrollbar{
  display: none;
}

.rules-list {
  list-style: none;
  padding: 0;
  counter-reset: list-counter;
}

.rules-list li {
  position: relative;
  color: white;
  padding: 12px 16px 12px 48px;
  margin: 12px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.rules-list li::before {
  content: counter(list-counter);
  counter-increment: list-counter;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(180deg, #eae3be, #FFA500); /* Gold gradient */
  color: black;
  font-weight: bold;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
