.billingHolder{
    .box{
        display: flex;
        align-items: center;
        padding: 5px 0 8px 0;
        margin: 12px 0;
        border-bottom: 1px solid #262626;
        p{
            margin: 0;
            margin-left: 20px;
        }
        .formControl{
            background: transparent;
            border: none;
            padding-left: 15px;
            color: #fff;
            &:focus-visible{
                outline: none;
            }
        }
    }
}