.heading{
    margin-bottom: 20px;
    h5{
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    cursor: pointer;
    svg{
        font-size: 28px;
        margin-right: 5px;
    }
    .memeNo{
        border-radius: 30px;
        border: 1px solid #FFF;
        padding: 5px 15px;
        font-weight: 500;
    }
}