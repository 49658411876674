.preview {
  width: 100%;
  height: 350px;
  background-color: black;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img {
    display: block;
    width: 100%;
    height: 100%;
    height: 350px;
    object-fit: contain;
  }
}
.profile {
  display: flex;
  padding: 20px;
  img {
  width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }
  p {
    padding: 10px 0 0 0;
  }
}

.story_video {
  display: block;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  object-fit: cover;
}
