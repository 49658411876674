.sender{
    margin-bottom: 35px;
    &.recive{
        .textBox{
            color: #fff;
           p{
            font-family: Gilroy;
            font-size: 12px;
           }
           .profileImg{
            img{
                border-radius: 0;
                border: none;
                width: 42px;
                height: 42px;

            }
           }
        }
    }
    .textBox{
        display: flex;
        align-items: flex-start;
        color: #FFE73C;
        .profileImg{
            margin-right: 10px;
            img{
                width: 50px;
                height: 50px;
                border: 2px solid #fff;
                border-radius: 50%;
            }
        }

        h5{
            font-size: 16px;
        }
    }
    .status{
        color: #FEBB59;
    }
    
}
.msg{
    font-family: 'Glass Antiqua', cursive;
    color: #fff;
}

.attachment{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img{
        width: 120px;
        margin-right: 10px;
    }
}
.sendBox{
    display: flex;
    align-items: center;
    span{
        cursor: pointer;
    }

    .attachBtn{
        margin-right: 8px;
        svg{
            color: #b5b3b8;
            font-size: 22px;
            &:hover{
                color: #FEBB59;
            }
        }
    }
    .sendBox{
        margin-right: 8px;
        position: relative;
        span{
            position: absolute;
            right: 8px;
            top: 4px;
            font-size: 20px;
            &:hover{
                color: #FEBB59;
            }
        }
        input{
            background: #201E23;
            border: none;
            color: #fff;
            min-width: 320px;
        }
    }
    .sendBtn{
        font-size: 22px;
        color: #ffc32c;
        &:hover{
            color: #b5b3b8;
        }

    }
}

.previewContainer {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.imagePreview {
  position: relative;
  margin: 5px 0;
  
  img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 8px;
    object-fit: cover;
  }

  .closeButton {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff4444;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    padding: 0;
    
    &:hover {
      background-color: #cc0000;
    }
  }
}

.ChatBoxHolder {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  scroll-behavior: smooth;
  width: 100%;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
}