@import "./include-media";
.dropdown {
  &.chatDropDown {
    border-radius: 12px 12px 0 0;
    background: #393240;
    box-shadow: 0px -11px 30px 0px rgba(0, 0, 0, 0.06);
    width: 260px;
    position: fixed;
    bottom: 0;
    right: 12px;
    padding: 0;
    z-index: 9999;
    @include media("<tablet") {
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 50%;
      background-color: #ffcd2f;
      bottom: 12px;
    }
    button {
      font-size: 14px;
      font-weight: 600;
      padding: 16px 22px !important;
      width: 100%;
      text-align: start;
      @include media("<tablet") {
        padding: 0 !important;
        text-align: center;
        &:hover {
          color: #fff;
        }
      }
    }
    .dropdown-menu {
      width: 100%;
      border-radius: 12px 12px 0 0;
      background: #393240;
      transform: unset !important;
      bottom: 38px !important;
      border: none;
      padding-top: 0;
      @include media("<tablet") {
        width: 250px;
        margin: 10px 0px;
        border-radius: 12px;
      }
    }
  }
  &.navDropdown {
    // position: absolute;
    // top: 620px;
    // min-height: 100vh;
    // @include media("<tablet-x"){
    //   position: relative;
    //   bottom: unset;
    // }
    height: 99px;
    .dropdown-toggle {
      background: none;
      font-size: 1rem;
      font-weight: 400;
      &:hover {
        color: #fff;
      }

      span {
        margin-right: 10px;
      }
    }

    img {
      width: 34px;
      height: 34px;
    }

    .dropdown-menu {
      background: #1c1c1c !important;

      a {
        font-family: "Poppins", sans-serif;
        font-size: 14px !important;

        &:hover {
          background: none;
        }
      }

      span {
        border-radius: 12px;
        background: #2f2c35;
        margin-right: 10px;
        padding: 7px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0 !important;
    height: auto;
    vertical-align: middle;
    line-height: normal;
    font-size: 18px;

    &:after {
      display: none;
    }

    &:hover {
      background-color: transparent;
      color: #ffcd2f;
    }
  }

  .dropdown-menu {
    border-radius: 14px;
    background: #261e2d;
    min-width: 175px;
    font-size: 12px;

    a {
      color: #fff;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      &:hover {
        background-color: #261e2d;
        color: #f6cc37;
      }

      i {
        border-radius: 8px;
        background: #2f2c35;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f6cc37;
        margin-right: 15px;
        font-size: 14px;
      }
    }
  }
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: unset !important;
  border: unset !important;
}
