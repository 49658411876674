@import "./include-media";

.btn {
  padding: 0 25px;
  border-radius: 30px;
  height: 38px;
  line-height: 38px;
  text-decoration: none;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: bold;
  font-size: 14px;
  transition: all ease 0.6;

  @include media('<xs-phone'){
    line-height: 30px;
  }
  @include media("<phone"){
    font-size: 12px !important;
  }
  &.flag{
    width: 50%;
    background: linear-gradient(180deg, #FFE299 0%, #F6B202 100%);
    padding: 23px 0;
    line-height: 0;
    color: #000;
    margin-top: 25px;
    // &:hover {
    //   background: linear-gradient(100%, #FFE299 0%, #F6B202 180) !important;
    //   font-weight: 700;
    //   color: #fff;
    //   background: transparent ;
    // }
    &.report{
      width: 30%;
    }
  }

  .btnHolder {
    max-width: 430px;
    display: flex;
    flex-direction: column;
    padding: 50px 0 0 0;

    &.btn-outline-light {
      width: 100%;
      border-radius: 30px;
      margin: 15px 0;
      padding: 12px 22px;
      border: 1.5px solid #ffcd2f;
    }

  }

  &.btn-outline-light {
    width: 100%;
    text-align: start;
    position: relative;
    height: 52px;
    line-height: 52px;
    margin-bottom: 15px;
    padding-left: 80px;
    font-weight: 400;
    font-family: 'PT Sans', sans-serif;

    svg {
      position: absolute;
      font-size: 16px;
      left: 55px;
      top: 18px;
    }

    &:hover {
      background: linear-gradient(180deg, #FFE299 0%, #F6B202 100%);
      color: #000;
      font-weight: 700;
    }
  }


  &.btn-bg {
    text-align: left;
    border-radius: 0px 30px 30px 0px;
    position: relative;
    width: 100%;
    height: 54px;
    line-height: 54px;
    overflow: hidden;
    font-family: $Poppins;
    font-weight: 500 !important;

    i {
      margin-right: 8px;
    }

    &:after {
      position: absolute;
      content: "";
      right: -25px;
      top: 0;
      width: 200px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.store {
      &:after {
        background-image: url("../Images/judge.png");
      }
    }

    &.trophy {
      &:after {
        background-image: url("../Images/trophy.png");
        opacity: 0.6;
      }
    }

    &.judge {
      &:after {
        background-image: url("../Images/store.png");
      }
    }
  }

  &.btn-primary {
    color: #000 !important;
    border-color: rgba(255, 160, 0, 0.35);
    background: linear-gradient(180deg, #ffe299 0%, #f6b202 100%);
    font-weight: 700;
    position: relative;
    transition: all linear 0.3s;

    &.del-btn {
      border-radius: 46px;
      background: #F00;
      border: none;
      color: #fff !important;
      font-size: 12px;
      padding: 22px;
      line-height: 0;

      &:hover {
        background: #F00 !important;
        color: #fff !important;
      }
    }

    &.del-button {
      border: none;
      background: none;
      color: #fff !important;
      font-size: 12px;

      &:hover {
        border: none;
        background: none !important;
        color: #fff !important;
      }
    }

    &.authButton {
      height: 48px;
      line-height: 48px;
      padding: 0 !important;

      @include media("<xs-phone") {
        height: 41px;
        line-height: 43px;
      }
    }

    &.authe-btn {
      @include media("<xs-phone") {
        margin-top: 5px !important;
      }

      @include media("<tablet") {
        margin-top: 9px !important;
      }

      @include media("<tablet-x") {
        margin-top: 10px;
      }
    }

    &.icon-btn {
      background: transparent !important;
      color: #FCF5A2 !important;
      border: 1px solid #FFEB65 !important;
      font-family: "Gilroy-Bold", sans-serif !important;
      position: relative;

      &:hover {
        background-color: #F6B202 !important;

        svg {
          display: block;
        }
      }

      svg {
        position: absolute;
        position: absolute;
        left: 20px;
        top: 50%;
        font-size: 20px;
        color: #fff;
        background: #000;
        border-radius: 50%;
        padding: 5px;
        width: 25px;
        height: 25px;
        transform: translateY(-50%);
        box-shadow: (0px 10px 10px rgba(0, 0, 0, 0.50));
        display: none;
      }

    }

    &.uploadButton {
      width: 100%;
      height: 350px;
      background: #2C2930;
      border: none;
    }

    &:hover {
      background: linear-gradient(220deg, #ffe299 0%, #f6b202 100%);
      color: #000 !important;
      transition: all linear 0.3s;
    }

    &:hover .fa {
      display: block;
    }
  }


  &.btn-secondary {
    color: #fff;
    border-color: rgba(255, 160, 0, 0.35);
    background: #f28e1c;
    border-radius: 4px;

    &:hover {
      background: #e58310;
    }
  }

  &.btnIcon {
    color: rgba(38, 50, 56, 1);
    height: 50px;
    line-height: 50px;

    &:hover {

      i,
      img {
        transform: translateX(3px);
        transition: all ease 0.3s;
      }
    }

    i {
      background-color: rgba(255, 255, 255, 0.34);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      line-height: 30px;
      text-align: center;
      margin-left: 10px;
      transform: translateX(0px);
      transition: all ease 0.3s;
    }

    img {
      transform: translateX(0px);
      transition: all ease 0.3s;
      width: 42px;
    }
  }
}