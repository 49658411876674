.iconBox {
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;
  padding: 0 10px;
  margin-right: 20px;
  img {
    width: 100%;
    height: auto;
  }
}
