@import "../../Style/base";
.list {
  border-radius: 30.5px;
  background: linear-gradient(180deg, #ffdf8d 0%, #f4b30e 100%);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #000;
  margin: 20px 0;
  padding: 0;
  list-style: none;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  li {
    display: inline-block;
    width: 33.33%;
    padding: 14px;
    @media screen and (max-width: 380px) {
      width: auto;
    }
    &:nth-child(2) {
      border: 1px solid #292929;
      border-bottom: 0;
      border-top: 0;
    }
  }

  &.historyList {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    background: transparent;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-size: 16px;
    li {
      width: unset;
      border: 0;
      padding: 0;
      display: flex;
      background: #292929;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      border-radius: 30px;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
      > div {
        flex: 0 0 33.33%;
      }

      .counter {
        padding-left: 0;
        span {
          background: linear-gradient(180deg, #b461f4 0%, #671ba3 100%);

          border-radius: 30px;
          width: 85px;
          display: block;
          padding: 14px;
        }
      }
      .checkboxHolder {
        text-align: right;
      }
    }
  }
}


.infoModal {
  [class*="modal-content"] {
      border-radius: 28px;
      border: 2px solid #dcdcdc;
      background: #1c1c1c !important;
      padding: 0 !important;
  }

  [class*="modal-header"] {
      padding: 0;
  }

  [class*="modal-body"] {
      background-color: transparent !important;
  }

  [class*="modal-dialog"] {
      max-width: 350px;
  }
h4{
  padding-left: 0 !important;
  text-align: center;
}
  .closeBtn {
      background-color: #d10a0b;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 30px;
      position: relative;
      top: -8px;
      right: -8px;
      cursor: pointer;
  }
  .text{
      padding: 0 21px;
  }
  
}