@import "../../Style/base";
#wrapper {
  position: relative;
  width: 100%;
  background-image: url(../../Images/login-bg.png);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
  @include media("<tablet"){
    background-image: none;
  }
}
.loginFrom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 70px 0 50px 0;
  &.minHeight{
    min-height: calc(100vh - 70px);
  }
  // overflow: hidden;
  @include media("<tablet"){
    padding: 20px 10px 5px 10px;
  }
  @include media("<tablet-x"){
    padding: 35px 10px 10px 10px;
  }
  
}
