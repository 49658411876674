.postHolder {
  margin-bottom: 35px;
  &:last-child{
    margin: 0;
  }
  .btnGroup {
    text-align: center;
    margin-top: -25px;
    button {
      border-radius: 0;
      background: rgba(255, 255, 255, 0.95);
      border: 0;
      transition: unset;
      height: 48px;
      line-height: 48px;
      &:hover{
        transition: unset;
      }
      svg{
        font-size: 25px;
      }
      &:first-child{
        border-right: 1px solid #000;
        border-radius: 30px 0 0 30px;
        svg{
            color: #00853D;
        }
      }
      &:last-child{
        border-radius: 0 30px 30px 0;
        svg{
            color: #F44336;
        }
      }
    }
  }
}
