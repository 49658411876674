@import "../../Style/base";
.section {
  padding: 0;
  max-width: 275px;
  margin: auto;
  height: unset;
  min-height: unset;
  overflow: unset;

  &.dotBg {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      background-image: url("../../Images/dotBg.png");
      position: absolute;
      left: 70%;
      top: -100%;
      bottom: 0;
      width: 1000%;
      z-index: -1;
    }
  }
  
}

.authHolder {
  padding: 50px 0;
  @include media("<xs-phone"){
    padding: 35px 0 ;
   }
   @include media("<xs-phone"){
    padding: 35px 0 ;
   }
}

.loginLinks {
  text-align: center;
  color: #fff;
  font-size: 12px;

  .dark {
    color: #888;
  }

  .light {
    color: #ffcd2f;
    text-decoration: none;
    padding-left: 5px;

    &:hover {
      color: #ffcd2f;
    }
  }

  span {
    text-decoration: underline;
    padding-left: 5px;
  }

  a {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &:hover {
      color: #fff;
    }
  }
}
.loginLink {
  .dark {
    color: #888;
  }

  .light {
    color: #ffcd2f;
    text-decoration: none;
    padding-left: 5px;

    &:hover {
      color: #ffcd2f;
    }
  }
}

.loginFrom {
  color: #fff;
  font-style: normal;
  position: relative;
  clear: both;
  padding: 30px 0 5px 0;
  @include media("<xs-phone") {
    padding: 25px 0 5px 0;
  }
  @include media("<tablet"){
    padding: 20px 0 5px 0;
   }
  

  &.nobefore {
    padding-bottom: 0;

    &:before {
      display: none;
    }

    &:after {
      top: 0;
    }
  }

  &:before {
    position: absolute;
    content: "";
    background-image: url("../../Images/coin.png");
    background-position: left center;
    // right: -35px;
    // bottom: -80px;
    right: -121px;
    bottom: -97px;

    width: 100px;
    height: 100px;
    background-size: 80px;
    background-repeat: no-repeat;
    z-index: -1;
    @include media("<widescreen") {
      right: -30px;
      bottom: -107px;
    }
    @include media("<phone"){
      background-size: 65px;
    }
  }

  &:after {
    position: absolute;
    content: "";
    background-image: url("../../Images/coin.png");
    background-position: center;
    left: -100px;
    top: -120px;
    width: 100px;
    height: 70px;
    background-repeat: no-repeat;
    background-size: 50px;
    z-index: -1;
    @include media("<xs-phone") {
      display: none;
    }
  }

  h1 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;

    @include media("<xs-phone") {
      font-size: 25px;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 20px;
    max-width: 350px;
    @include media("<xs-phone") {
      font-size: 13px;
      margin-bottom: 8px;
    }
  }

  .password {
    color: #f00;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    float: right;
    margin-bottom: 30px;

    cursor: pointer;
  }

  .light {
    color: #ffcd2f;
    padding: 0 0 0 5px;

    &:hover {
      color: #ffcd2f;
    }
    .underline{
      text-decoration: underline;
      color: #FCF5A2;
    }
  }

  .dark {
    padding: 10px 0 0 0;
    margin: 0;
  }
}
.eye {
  position: relative;

  span {
      position: absolute;
      top: 9px;
      right: 15px;
      cursor: pointer;
      color: #9b9a9a;
      font-size: 18px;
  }
}