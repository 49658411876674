@import "../../Style/base";
.prizeList {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  font-family: $Poppins;

  li {
    display: flex;
    align-items: center;
    background-color: #292929;
    border-radius: 25px;
    position: relative;
    padding-left: 70px;
    margin-bottom: 15px;
    span {
      font-weight: 700;
      width: 40px;
      height: 40px;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;  
      font-style: italic;
      font-family: $pt;
      &:after {
        position: absolute;
        content: "";
        left: 0px;
        right: -25px;
        top: 0px;
        bottom: 0;
        border-style: solid;
        border-width: 45px 45px 1px 0px;
        border-color: #a73afc transparent transparent transparent;
        z-index: -1;
        border-radius: 8px;
      }
      &:before {
        position: absolute;
        content: "";
        left: 0px;
        right: -30px;
        top: 5px;
        bottom: 0;
        border-style: solid;
        border-width: 45px 45px 1px 0px;
        border-color: #570A94 transparent transparent transparent;
        z-index: -1;
        border-radius: 8px;
      }
    }
    img {
      width: 22px;
      height: 22px;
    }
    p {
      margin: 0;
      padding: 12px;
    }
  }
}
