@import "../../Style/base";
.postWrapper {
  padding: 30px 0;
  border-bottom: 1px solid #262626;
  &:last-child {
    border-bottom: none;
  }
  .postHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    margin-bottom: 20px;
    .profile {
      display: flex;
      align-items: center;
      .imgBox {
        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          object-position: top;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }
      .userDetail {
        font-size: 12px;
        margin-left: 10px;
        cursor: pointer;
        h6 {
          color: #fff;
          margin-bottom: 0;
        }
        p {
          margin: 0;
        }
      }
    }
  }

  .PostDetails {
    font-family: $pt;
    color: #fff;
    font-weight: 300;
    text-transform: capitalize;
    font-size: 13px;
    padding: 0 25px;
    margin-bottom: 20px;
    .tags {
      color: #74bbf3;
    }
    p {
      margin-bottom: 5px;
      overflow-wrap: break-word;
    }
  }
  .imgBox {
    img {
      display: block;
      height: auto;
      width: 100%;
      border-radius: 18px;
    }
  }
  .postFooter {
    margin: 0;
    padding: 0 25px;
    list-style: none;
    font-family: $Poppins;
    color: #fff;
    li {
      display: inline-block;
      margin-right: 15px;
      cursor: pointer;
      &:first-child{
        &:hover{
          opacity: unset;
        }
      }
      &.active{
        color: #2492f8;
        img{
          filter: brightness(0) saturate(100%) invert(48%) sepia(89%) saturate(3099%) hue-rotate(191deg) brightness(102%) contrast(94%);
          
        }
      }
      &:hover {
        opacity: 0.6;
      }
      img {
        width: 22px;
        margin-right: 8px;
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
