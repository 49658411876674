.searchForm {
  position: relative;
  width: 100%;
  .searchDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #3d3b3f;
   
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    color: black;
    border-radius: 8px;
  }
}
.search {
  &.contactList {
    input {
      margin-bottom: 0;
      border-radius: 30px !important;
      background: #2d2933;
    }
  }
  &.badgeList {
    margin-bottom: 32px;
    input {
      border-radius: 8px;
      background: #3d3b40;
    }
  }
  &.fullWidth {
    margin-bottom: 35px;

    input {
      background: #201e23;
      border-radius: 30px !important;
      padding: 15px 50px;

      &:focus {
        color: #fff;
      }
    }
  }

  span {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
  }

  input {
    background: rgba(255, 255, 255, 0.13);
    border: 8px;
    border-radius: 8px !important;
    padding: 12px 40px 12px 12px;

    &::placeholder {
      color: rgba(255, 234, 234, 0.392);
    }

    &:focus {
      color: #fff;
      background: rgba(255, 255, 255, 0.13);
    }
  }
}
