.nav-tabs {
  justify-content: center;
  border-bottom: 0;
  font-family: $pt;
  font-weight: 600;
  @include media("<xs-phone") {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    padding-bottom: 5px;
    &::-webkit-scrollbar {
      height: 4px; /* height of horizontal scrollbar ← You're missing this */
      width: 4px; /* width of vertical scrollbar */
    }
  }
  &.double {
    background: #201e23;
    border-radius: 30px;

    .nav-item {
      flex: 0 0 50%;

      button {
        &:first-child {
          border-radius: 30px;
        }
      }
    }
  }
  &.full-tab {
    .nav-item {
      flex: 0 0 100%;
    }
  }

  &.noBg {
    border-bottom: 1px solid #2c2948;
    .nav-item {
      flex: 1 0 auto;
      max-width: 50%;

      .nav-link {
        background-color: transparent;
        border: none;
        border-radius: 0 !important;
        color: #544e5a;

        &:hover {
          border: none;
        }

        &.active {
          background: none;
          border-bottom: 1px solid #f9bf29;
          color: #fff !important;
        }
      }
    }
  }

  &.modalTabs {
    background: transparent;
    justify-content: left !important;

    &button {
      @include media("<xs-phone") {
        text-align: left;
      }
    }

    .nav-item {
      flex: 0 0 auto;
      padding: 0 10px;
      justify-content: flex-start;
    }

    .nav-link {
      width: 100%;
      background: transparent !important;
      border-radius: 0;
      color: #fff !important;
      border: none !important;
      padding: 0 0 0 30px !important;
      font-size: 14px;
      // padding: 0 40px !important;

      &:hover {
        border: none !important;
      }

      &.active {
        background: none;
        color: #f9bf29 !important;
      }
    }
  }

  .nav-item {
    flex: 0 0 33.33%;
    @include media("<xs-phone") {
      flex: 1 0 auto;
    }

    &:first-child {
      .nav-link {
        border-radius: 30px 0 0 30px;
      }
    }

    &:last-child {
      .nav-link {
        border-radius: 0px 30px 30px 0px;
      }
    }

    .nav-link {
      width: 100%;
      background: #201e23;
      border-radius: 0;
      color: #b6b6b6;
      border: 1px solid #201e23;
      padding: 14px;

      &:hover {
        border: 1px solid #fedc84;
      }

      &.active {
        background: linear-gradient(180deg, #fedc84 0%, #f9bf29 100%);
        color: #000;
      }
    }
  }
}

.CustTabContent {
  background: #2c2930;
  border-radius: 30px;
  padding: 15px;
  margin: 10px;
  font-size: 12px;
  color: #9892a5;
  @include media("<xs-phone") {
    margin: 10px 0px;
  }

  &.faq {
    color: #fff;
  }
}
