@import "../../Style/base";
.logo{
  position: relative;
  width: 130px;
  float: left;
  &.logoStart{
    float: left;
    width: 180px;
  }
  &.login{
    margin: auto;
    float: unset;
    width: 180px;
    @include media("<xs-phone") {
      width: 140px !important;
    }
  }

  a{
    display: block;
  }

  img{
    display: block;
    width: 100%;
    height: auto;
  }
}