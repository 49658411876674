@import "Style/base";

@keyframes image-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loaderComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: $color-black-fade;
  z-index: 9990;

  @media (prefers-reduced-motion: no-preference) {
    img {
      width: 60px;
      height: 60px;
      padding: 12.5px;
      animation: image-spin infinite 0.8s linear;
      @include apply-at-lg-size {
        width: 75px;
        height: 75px;
      }
    }
  }

  &.showLoader {
    display: flex;
  }
}
