@import "../../Style/base";
.badgeHolder{
    background: #292929;
    border-radius: 40px;
    padding: 20px 35px;
    margin-bottom: 20px;
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        color: #fff;
        .btn{
            cursor: pointer;
            padding: 0;
            line-height: unset;
            height: unset;
            &:hover{
                color: #F9C02F;
            }
        }
    }
    .listBadge{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 12px;
        text-align: center;
        li{
            .iconBox{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin: auto;
                margin-bottom: 10px;
                img{
                    width: 100%;
                    height: auto;
                    padding: 8px;
                }
            }
            p{
                margin: 0;
            }
        }
    }
}