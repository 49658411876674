.supportSection {
    .support {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            justify-content: space-between;
            border-radius: 15px;
            background: #201E23;
            box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20);
            padding: 21px 20px 12px 20px;
            margin: 20px 0;

            .head {
                display: flex;
            }

            img {
                width: 50px;
                height: 50px;
                margin-right: 17px;
                margin-top: 8px;
            }

            .box {
                line-height: normal;
                cursor: pointer;
            }

            .title {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }

            .text {
                color: #FFE73C;
                font-size: 12px;
            }

            .date {
                color: #FFF;
                font-size: 14px;
                line-height: 0;
                font-family: 'Glass Antiqua';
            }

            span {
                color: #FEBB59;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-top: 4px;
                padding-right: 10px;
            }
        }
    }
}