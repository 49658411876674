@import "../../Style/base";
.box {
  &.cardBox{
    background-color: #292929;
    padding: 20px 0;
    border-radius: 20px;
    button{
      font-weight: 500;
      height: 38px;
      line-height: 38px;
    }
    h3{
      font-size:32px;
    }
    input{
      text-align: center !important;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    h3 {
      font-weight: 700;
      text-shadow: 0 0 0px #c7943c, 0 0 3px #c7943c;
      margin: 0;
      margin-left: 10px;
    }
  }
  .inputBox {
    text-align: center;
    background: #292929;
    padding: 20px;
    border-radius: 15px;
    font-family: $pt;
    .buyCoinInput {
      width: 100%;
      background: #454545;
      border-radius: 30px;
      border: none;
      color: #999;
      padding: 0.6rem 20px;
      margin-bottom: 1rem;
     
    }
  }
  .currencyBox{
    background: #292929;
    padding: 5px 15px;
    position: absolute;
    top: 50%;
    right: 5px;
    border-radius: 30px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
