// SearchResult.module.scss

.searchResults {
  list-style: none;
  padding: 0;
}

.resultItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
  padding: 12px 18px;
  transition: transform 0.3s ease-in-out;
  gap: 10px;
  &:last-child{
    border-bottom: 0;
    margin-bottom: 0;
  }
  &:hover {
    background-color: #201e23;
    transition: all ease 0.6s;
  }
}

.resultImage {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.resultDetails {
  flex: 1;
  margin-right: 10px;
  cursor: pointer;
}

.resultTitle {
  font-size: 16px;
  margin-bottom: 5px;
  color: white;
}

.resultDescription {
  color: #ffcd2f;
}

// Add additional styling as per your requirements
