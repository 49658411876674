@import "../../Style/base";

.icon {
  width: 35px;
  height: 35px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: linear-gradient(180deg, #ff6f91 0%, #ae6fff 100%);
  border-radius: 14px;
  display: inline-block;
  border: 2px solid #FF6F91;
  cursor: pointer;
  position: relative;
  z-index: 2;

  svg {
    font-size: 22px;
  }
}

.scoreBoard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 60%;
  color: #fff;
  transform: translateX(-50%);
  padding: 8px 0;

  @include media("<xs-phone") {
    width: 75%;
    font-size: 10px;
    bottom: 5px;
    padding: 5px 0;
  }

  span {
    position: relative;
    border-right: 1px solid #fff;
    flex: 0 0 50%;
    text-align: center;

    &:last-child {
      border-right: 0;
    }

  }

}

.btn {
  width: 50%;

  @include media("<xs-phone") {
    width: 100%;
  }
}

.bannerCard {
  height: 400px;
  width: 100%;
  text-align: center;

  .bannerImg {
    margin: 0 auto;
    padding: 40px 0 0 0;
    width: 130px;
  }

  h2 {
    background: linear-gradient(180deg, #FFE5A3 19.06%, #DAA00A 91.15%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 20px 0 0 0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
  }

}