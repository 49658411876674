.badgeBox{
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 4px;              /* height of horizontal scrollbar ← You're missing this */
        width: 4px;               /* width of vertical scrollbar */
      }

      &.explosreBox{
        flex-wrap: nowrap !important;
        h5{
          span {
            margin-bottom: 8px !important;
          }
        }
      }
}