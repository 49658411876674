.transaction { 
    margin: 0;
    padding: 0;
    list-style: none;
    min-height: 389px;
    overflow: auto;
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 15px;
        background: #292929;
        box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20);
        padding: 10px;
        margin: 10px 0;

        .head{
            display: flex;
        }

        img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }

        .title {
            color: #FFF;
            font-size: 18px;
            font-weight: 800;
            margin: 0;
        }
        span {
            color: #7C7C7C;
            font-size: 12px;
        }  
    }
}
.headingBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    a{
         color: #FFCD2F;
         text-decoration: none;
    }
}

.cardBox{
    background-color: transparent;
}
.minHeight{
    min-height: unset;
}