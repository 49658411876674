@import "../../Style/base";

.prizeList {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  font-family: $Poppins;
  font-weight: 500;
  line-height: 20px;
  overflow-y: auto;
  max-height: 305px;
  &.modalList {
    color: #fff;
    &::-webkit-scrollbar-track {
      background: #3d3745;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff;
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background: #3d3745;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.32);
    border-radius: 15px;
  }

  li {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
    gap: 15px;
    cursor: pointer;
    &:last-child {
      margin: 0;
    }

    .profile {
      position: relative;

      .status {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      img {
        width: 40px;
        border-radius: 50%;
        height: 40px;
        object-fit: cover;
        border: 3px solid #fff;
      }
    }
  }
}
